css * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

}

*{
  font-family: 'Hanken Grotesk',sans-serif;
}

.carousel-item {
  border-radius: 12px;
}




.mainlw {
  align-items: center;
  justify-content: center;
  /* left: 50%; */
  /* top: 50%; */
  /* position: absolute; */
  width: 100%;
  height: 100vh;
  display: flex;
}

.inactivecat{
  opacity: 0.6742081447963801;
}

.whatsapp_icon{
  position: fixed;
  display: inline-flex;
  bottom: 13%;
  right: 4%;
  width: 60px;
  height: 60px;
  z-index: 1055;
}

.activecat{
  opacity: 1;
}

.dot-spinner {
  --uib-size: 3.8rem;
  --uib-speed: .9s;
  --uib-color: #183153;

  height: 100vh;
  width: 100%;
  position: relative;
  /* display: flex; */
  align-items: center;
  /* justify-content: flex-start; */
  height: var(--uib-size);
  width: var(--uib-size);
}

.dot-spinner__dot {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  /* justify-content: flex-start; */
  height: 100%;
  width: 100%;
}

/* .facebook{
  fill: #595c5d;
}
.gmail{
  fill: #595c5d;
} */
.dot-spinner__dot::before {
  content: '';
  height: 25%;
  width: 25%;
  border-radius: 50%;
  background-color: var(--uib-color);
  transform: scale(0);
  opacity: 0.5;
  animation: pulse0112 calc(var(--uib-speed) * 1.111) ease-in-out infinite;
  box-shadow: 0 0 20px rgba(18, 31, 53, 0.3);
}

.footershadow{
  box-shadow: 0 -3px 10px 3px #dddddd;
}

.dot-spinner__dot:nth-child(2) {
  transform: rotate(45deg);
}

.dot-spinner__dot:nth-child(2)::before {
  animation-delay: calc(var(--uib-speed) * -0.875);
}

.dot-spinner__dot:nth-child(3) {
  transform: rotate(90deg);
}

.dot-spinner__dot:nth-child(3)::before {
  animation-delay: calc(var(--uib-speed) * -0.75);
}

.dot-spinner__dot:nth-child(4) {
  transform: rotate(135deg);
}

.dot-spinner__dot:nth-child(4)::before {
  animation-delay: calc(var(--uib-speed) * -0.625);
}

.dot-spinner__dot:nth-child(5) {
  transform: rotate(180deg);
}

.dot-spinner__dot:nth-child(5)::before {
  animation-delay: calc(var(--uib-speed) * -0.5);
}

.dot-spinner__dot:nth-child(6) {
  transform: rotate(225deg);
}

.dot-spinner__dot:nth-child(6)::before {
  animation-delay: calc(var(--uib-speed) * -0.375);
}

.dot-spinner__dot:nth-child(7) {
  transform: rotate(270deg);
}

.dot-spinner__dot:nth-child(7)::before {
  animation-delay: calc(var(--uib-speed) * -0.25);
}

.dot-spinner__dot:nth-child(8) {
  transform: rotate(315deg);
}

.dot-spinner__dot:nth-child(8)::before {
  animation-delay: calc(var(--uib-speed) * -0.125);
}

@keyframes pulse0112 {

  0%,
  100% {
    transform: scale(0);
    opacity: 0.5;
  }

  50% {
    transform: scale(1);
    opacity: 1;
  }
}





.carousel-item {
  width: 300px;
  height: 400px;
  object-fit: cover !important;

}

.carousel-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.whole-imgae {
  cursor: pointer;
  /* width: 100%;
  height: 230px; */
  
 width: 100%;
 height: 100%;
  overflow: hidden;
  border-radius: 15px;
}

.card-img-top{
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 15px;
}

.ribbon-offer {
  border-radius: 15px;
  --f: 0px;
  --r: 13px;
  --t: 0px;

  position: absolute;
  /* inset: var(--t) calc(-1*var(--f)) auto auto; */
  /* padding: 0px 10px var(--f) calc(10px + var(--r)); */
  /* clip-path: polygon(0% 0%, 34% 0%, 31% 49%, 34% 100%, 0% 100%);; */
  /* background: #BD1550; */
  box-shadow: 0 calc(3*var(--f)) 0 inset #0005;
  font-size: 14px;
  color: #fff;
  /* width: 100%; */
  padding: 2px 10px;
  margin-bottom: 4px;
  bottom: 1%;
  left: 0%;
  z-index: 2;
}

.offer_heading{
  background: white;
  padding: 2px 4px;
  border-radius: 5px;
  /* left: 0%; */
}




.ribbon-offer::before{
  content: '';
  position: absolute;
  left: 8px;
  top: 0px;
  background: linear-gradient(45deg, #ffffff, #000000, #ffffff, #000000, #ffffff, #000000, #ffffff);
  background-size: 400%;
  width: calc(90% + 1px);
  height: calc(90% + 3px);
  z-index: -1;
  border-radius: 5px;
  animation: glower 50s linear infinite;
}

@keyframes glower {
  0% {
    background-position: 0 0;
  }
  
  50% {
    background-position: 400% 0;
  }
  
  100% {
    background-position: 0 0;
  }
}


.card-img-top img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
}




.wrapper {
  width: 100%;
  margin: 15px auto;
  min-height: 75svh;
}

.cards_wrap {
  display: grid;
  grid-template-columns: repeat(4,1fr);
 
}
/* 
.whole-imgae::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.36);
  z-index: 0;
  border-radius: 6px;
} */

.cards_wrap .card_item {
  padding: 7px;
  width: 100%;

}

.date_holder{
  border: 1px solid #dedede;
border-radius: 0.5rem;
background-color: white;
font-size: 14px;
max-height: fit-content;

}


.date_hold{
  font-size: 18px;
}

.date_gray{
  color: rgba(0, 0, 0, 0.6)
}

.date_ind{
  line-height: 1.25;
}

.day_holder{
  background-color: rgba(0, 0, 0, 0.05);
 border-top-right-radius: 0.5rem;
 border-top-left-radius: 0.5rem;
}

#datesModal{
  background-color: rgba(0,0,0,0.7)
}

.cards_wrap .card_inner {
  background: #fff;
}

.cards_wrap .card_top {
  width: 100%;
  border-radius: 15px;
  overflow: hidden;
  padding-bottom: 0;
}

.cards_wrap .card_bottom {
  padding: 15px;
}

.swiper-pagination-bullets {
  bottom: 8%;
}

.cards_wrap .card_bottom .card_category {
  text-transform: uppercase;
  text-align: center;
}

.cards_wrap .card_bottom .card_info {
  padding: 15px;
  margin: 10px 0;
  border: 1px dashed #0071bc;
}

.pdfbookfooter{
  position: fixed;
  bottom: 0%;
  left: 0%;
  width: 100%;
  background-color: white;
}

.cards_wrap .card_bottom .card_info .title {
  color: #0071bc;
  font-size: 18px;
  margin-bottom: 5px;
}

.cards_wrap .card_bottom .card_creator {
  text-align: center;
}

.card-img-top {
  object-fit: cover;
  cursor: pointer;
  border-radius: 15px;
}

.fordesktop {
  /* display: flex;
 
  align-content: start;
  padding: 3rem;
  padding-top: 0.5rem;
  height: 100vh;
  overflow: auto;
  scrollbar-width: none; */
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* place-items: center; */
  /* align-items: center; */
  /* justify-content: center; */
}

.carousel-inner {
  padding: 2px 70px;
}

/* .desktopCard {
  padding: 0 70px 60px 70px;
} */

.card.cardradius {
  box-shadow: 1px 2px 6px #e6e5e5;
  height: auto;
  width: 330px;
 border-radius: 15px;
  aspect-ratio: 20/19;
}

.line_height,.text-detail-price{
  line-height: 1;
}


@media screen and (max-width: 991px){
  .cards_wrap{
    grid-template-columns: repeat(2,1fr);
  }
}





@media (max-width: 768px) {


  .cards_wrap{
    grid-template-columns: repeat(2,1fr);
  }
  .card-img-top {
    cursor: pointer;
    width: 100%;
    /* height: 230px; */
    object-fit: cover;
    border-radius: 15px;
  }

  .card-img-top img {
    width: 100%;
    height: 100%;
    border-radius: 15px;
  }

  .cards_wrap .card_item {
   
    padding: 7px;
  }

  .carousel-item {
    min-width: 300px;
    height: 400px;
  }

  .carousel-item img {
    width: 100%;
    height: 100%;
  }

  .fordesktop {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 0;
    padding: 0rem;
    height: auto;
    place-items: center;
  }

  .carousel-inner {
    padding: 0 0px;
  }

  .desktopCard {
    padding: 0 0px;
  }

}

@media (max-width: 528px) {
  .card-img-top {
    cursor: pointer;
    width: 100%;
    height: 100%;
    aspect-ratio: 4/3;
    /* height: 130px; */
    object-fit: cover;
    border-radius: 15px;
  }

  .card.cardradius {
    box-shadow: 1px 2px 6px #e6e5e5;
    width: auto;
    height: auto;
    /* height: 354px; */
    /* height: 200px; */
   border-radius: 15px;
    aspect-ratio: 20/19;
  }

  .cards_wrap .card_item{
    padding: 0px;
  }

  .card.bordercardBody-0 {
    box-shadow: none !important;
  }

  .swiper-zoom-container>img,
  .swiper-zoom-container>svg,
  .swiper-zoom-container>canvas {
    width: 100% !important;
    /* height: 100% !important; */
    object-fit: cover;
    /* border-radius: 0% !important; */
    aspect-ratio: 4/3;
  }

  /* .cards_wrap{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  } */

  
  .cards_wrap{
   display: grid;
   grid-template-columns: repeat(1,1fr);
   padding: 0 1.2rem;
   /* place-items: center; */
  }

  .card_item{
    width: 100%;
  }

  .ribbon-offer {
    border-radius: 15px;
    --f: 0px;
    --r: 13px;
    --t: 0px;
  
    position: absolute;
    /* inset: var(--t) calc(-1*var(--f)) auto auto; */
    /* padding: 0px 10px var(--f) calc(10px + var(--r)); */
    /* clip-path: polygon(0% 0%, 34% 0%, 31% 49%, 34% 100%, 0% 100%);; */
    /* background: #BD1550; */
    box-shadow: 0 calc(3*var(--f)) 0 inset #0005;
    font-size: 14px;
    color: #fff;
    /* width: 100%; */
    padding: 2px 10px;
    margin-bottom: 4px;
    bottom: 1%;
    left: 0%;
    z-index: 2;
  }
  
  .offer_heading{
    background: white;
    padding: 2px 4px;
    border-radius: 5px;
    /* left: 0%; */
  }
  
  .desktopCard{
    margin-top: -6%;
  }
  
  
  
  .ribbon-offer::before{
    content: '';
    position: absolute;
    left: 8px;
    top: 0px;
    background: linear-gradient(45deg, #ffffff, #000000, #ffffff, #000000, #ffffff, #000000, #ffffff);
    background-size: 400%;
    width: calc(90% + 1px);
    height: calc(90% + 3px);
    z-index: -1;
    border-radius: 5px;
    animation: glower 50s linear infinite;
  }
  
  @keyframes glower {
    0% {
      background-position: 0 0;
    }
    
    50% {
      background-position: 400% 0;
    }
    
    100% {
      background-position: 0 0;
    }
  }
  



  .card-img-top img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 15px;
  }


  

  .card_top img {
    object-fit: cover;
    transition: 0.5s all ease-in-out;
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
    display: block;
    width: 100%;
    object-fit: cover;
    height: 178px;
    border-radius: 15px;
  }

  /* .cards_wrap .card_item {
    width: 100%;
    padding: 6px;
  } */

  .whatsapp_icon{
    position: fixed;
    display: inline-flex;
    bottom: 8%;
    right: 5%;
    width: 60px;
    height: 60px;
    z-index: 1055;
  }
  

  .carousel-item {
    min-width: 300px;
    height: 220px;
    object-fit: cover;
  }

  .carousel-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .carousel-item.currentSlide.active {
    width: 300px;
    height: 220px;
    object-fit: cover;
  }

  .currentSlide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }


}

.date_indicator{
  font-size: 13px;
  opacity: 75%;
}

.date{
  font-size: 14px;
  font-weight: 500;
}

#bookingBtn{
  font-family: 'Hanken Grotesk', sans-serif;
  font-size: clamp(12px, 1.5vw, 32px) !important;
}

button {
  /* font-size: clamp(12px, 1.5vw, 32px) !important; */
  /* border: 1px solid rgb(221, 221, 221) !important; */
}

.price {
  font-size: clamp(16px, 1.1vw, 32px) !important;
}

.dropdown-menu {
  font-size: clamp(12px, 1vw, 32px) !important;
}

hr {
  /* margin: 15px !important; */
  color: rgb(178 172 172) !important;
}

.card-detail {
  line-height: 18px;
}

p.text-detail-price {
  color: #000000;
  font-size: clamp(12px, 1.1vw, 32px);
}

p.text-detail-day {
  font-size: clamp(12px, 1vw, 32px);
}

.card-detail h5 {
  font-size: clamp(13px, 1.3vw, 32px);
}

.icon-type {
  text-align: center;
  font-size: 28px;
  padding: 4px 6px 4px 6px;
  color: rgb(83, 81, 81);
}

.footer-type {
  margin-top: 10px;
}

h5.footer-type {
  font-size: clamp(20px, 1.3vw, 32px) !important;
}

i.fa-brands {
  font-size: clamp(18px, 2vw, 32px) !important;
}

.icon-type:hover {
  transform: scale(1.3);

}

/* searchbar */
/* input#searchInput {
  border-radius: 15px 0px 0 15px;
} */

button.btn.btn-outline-white.bg-white.p-2 {
  border-radius: 0px 15px 15px 0px;
}

.button-container::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.cards_wrap::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.button-container {
  display: flex;
  max-width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  -webkit-box-shadow: -1px 8px 10px -9px #dddddd;
-moz-box-shadow: -1px 8px 10px -9px #dddddd;
box-shadow: -1px 8px 10px -9px #dddddd;
}

.category-button {
  /* padding: 5px 10px; */
  margin: 5px;
}

.hrf {
  margin: 10px;
  background: rgb(247, 237, 237);
  width: 1px;
}

.form-control:focus {
  border-color: #ced4da !important;
  box-shadow: none !important;
}

.form-control{
  transition: opacity 0.5s ease;
}


button.modal-border-zero {
  border: 0 !important;
}

.modal{
  background-color: rgba(0, 0, 0, 0.7);
}

/* .modal-dialog {
  justify-content: center;
  align-items: center;
  display: flex;
  height: 100%;
  position: relative;
  margin: 0 !important;
} */

#eventModal{
  margin: 0 !important;
}

#book-container .modal{
  background-color: rgba(0, 0, 0, 0.4);
}

.bookingWidgetContainer .modal{
  background-color: rgba(0, 0, 0, 0.4);
}


.moddd {
  z-index: 13;
  background-color: rgba(0, 0, 0, 0.4);
}

h6.card-title-modal {
  font-size: clamp(16px, 1.1vw, 32px) !important;
  font-weight: 900;
}


.card.border-0 {
  border-top-left-radius: 23px;
  border-top-right-radius: 23px;
}

.image-modal-pic img {
  border-top-left-radius: 23px;
  border-top-right-radius: 23px;
}

.image-modal-pic {
  width: 15rem !important;
  height: 28rem !important;
}

.image-modal-pic img {
  width: 100% !important;
  height: 100% !important;
}


.carousel-indicators {
  position: absolute !important;
  bottom: -15px !important;
  margin: auto !important;
}

.carousel-indicators {
  width: 60px !important;
  border-radius: 23px !important;
}



.offcanvas-header {
  display: flex !important;
}



/* crausol */
.navbar-brand-image {
  width: 40px;
  height: 40px;
}

.navbar-brand-image img {
  width: 100%;
  height: 100%;
}

/* Add this CSS to your stylesheet or CSS file */
.carousel-item {
  position: relative;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(2.5, 0.5, 0.5, 0.7);
}


button.btn-close.text-reset.toggleDrawercloseEvent {
  color: #fff !important;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

button.oultlinedarkpdf {
  border: 1px solid #000000 !important;
}

.card.bordercardBody-0 {
  border: solid white !important;
  -webkit-box-shadow: 0px -2px 5px 1px rgba(222,222,222,1);
-moz-box-shadow: 0px -2px 5px 1px rgba(222,222,222,1);
box-shadow: 0px -2px 5px 1px rgba(222,222,222,1);
}

#verticalCarousel {
  flex-direction: column;
}

.dots-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dot {
  height: 8px;
  width: 8px;
  background-color: #bbb;
  border-radius: 50%;
  margin: 5px 3px;
  cursor: pointer;
}

.active {
  background-color: #333;
}

.cardshadow {
  box-shadow: 0 0px 5px 3px rgba(0, 0, 0, 0.2);
}



/* eventModal */
/* .eventCardfont {
  font-size: clamp(13px, 1.2vw, 32px) !important;
} */




.modal-backdrop.fade.show {
  background-color: transparent !important;
  position: relative !important;
}

div#pdfModal {
  background-color: #000000 !important;
}

.strike {
  font-size: 10px;
  /* color: #797a7a; */
  font-weight: 500;
}



.sticky {
  position: sticky;
  top: 0;
  z-index: 1000;
  /* Add other styling as needed */
}

.modal-backdrop {
  height: 0 !important;
}

.highlights{
  max-height: 100%;
  transition: all 0.5s ease-in;
  overflow: auto;
}

.close{
  max-height: 41px;
  overflow: hidden;
  /* transition: all 0.5s ease-out; */
}


.highlight_accord{
  display: flex;
    justify-content: space-between;
    align-items: center
}

.tabs_list {
  display: flex;
  justify-content: space-around;
  padding: 0;
  list-style: none;
  position: relative;
  /* padding-bottom: 5px; */
  border-bottom: 1px solid rgba(178 172 172);
}

.tabs_list li {
  padding: 5px 8px;
}

.tabs_list li.activetab {
  /* padding: 5px 8px; */
  /* border-bottom: 1px solid black; */
  /* transition: all 0.5s; */
  position: relative;
}

.tabs_list li::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: #000000;
  opacity: 0;
}

.tabs_list li.activetab::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: #000000;
  opacity: 1;
  transition: all 0.5s;
}


/* caption */

h5.caption-font {
  font-size: clamp(18px, 2.5vw, 32px) !important;
}


.ribbon-2 {
  border-top-right-radius: 15px;
  --f: 0px;
  --r: 13px;
  --t: 0px;

  position: absolute;
  inset: var(--t) calc(-1*var(--f)) auto auto;
  padding: 0px 10px var(--f) calc(10px + var(--r));
  clip-path:
    polygon(0 0, 100% 0, 100% calc(100% - var(--f)), calc(100% - var(--f)) 100%,
      calc(100% - var(--f)) calc(100% - var(--f)), 0 calc(100% - var(--f)),
      var(--r) calc(50% - var(--f)/2));
  background: #BD1550;
  box-shadow: 0 calc(3*var(--f)) 0 inset #0005;
  font-size: 14px;
  color: #fff;
  z-index: 2;
}

.ribbon_heading{
  /* background: white; */
  padding: 2px 4px;
  border-radius: 5px;
  /* left: 0%; */
}

.ribbon-2::before {
  content: '';
  position: absolute;
  width: 20px;
  transform: rotate(-45deg);
  height: 100%;
  filter: blur(20px);
  /* background-image: linear-gradient(
      120deg,
      rgba(255,255,255, 0) 30%,
      rgba(255,255,255, .5),
      rgba(255,255,255, 0) 70%
  ); */
  background: rgba(255, 255, 255, 0.7);
  top: 0;
  left: -100px;
  right: 20px;
  /* z-index: ; */
  animation: shine 3s infinite ease-in-out; /* Animation */
}




@keyframes shine {
  0% {left: -80px}
  20% {left: 83%}
  100% {left: 83%}
}

.form-control::placeholder{
  /* color: red; */
  opacity: 0.4;
}

.terms{

  font-size: 13px;
  opacity: 75%;
  margin-top: 10px;
}





.carousel-container {
  position: relative;
  width: 100%;
  overflow: hidden;

}

.carousel-inner-t {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.carousel-item {
  min-width: 100%;
  object-fit: cover;

}

.buttonpdf{
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 1.1rem;
  line-height: 1;
  font-size: clamp(12px, 1.5vw, 32px) !important;
}



.btn-alt1 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 1.1rem;
  position: relative;
  overflow: hidden;
  text-decoration: none;
  border: var(--btn-border);
  color: var(--btn-text-color);
  background: var(--btn-bg);
  line-height: 1;
}

.btn-alt1:not(:last-child) {
  margin-bottom: 3rem;
}

.btn-alt1::before,
.btn-alt1::after {
  content: "";
  display: block;
  position: absolute;
  height: 100%;
  top: 0;
}

.btn-alt1::before {
  background: rgba(255, 255, 255, 0.7);
  width: 60px;
  left: 0;
  filter: blur(30px);
  animation: shine-alt1 3s ease-in-out infinite;
} 

.btn-alt1::after {
  background: rgba(255, 255, 255, 0.5);
  width: 30px;
  left: 30px;
  filter: blur(5px);
  animation: shine-alt2 3s ease-in-out infinite;
}

@keyframes shine-alt1 {
  0% {
    opacity: 0.5;
    transform: translateX(-100px) skewX(-15deg);
  }

  50%,
  100% {
    opacity: 0.6;
    transform: translateX(300px) skewX(-15deg);
  }
}

@keyframes shine-alt2 {
  0% {
    opacity: 0.5;
    transform: translateX(-100px) skewX(-15deg);
  }

  50%,
  100% {
    opacity: 0.6;
    transform: translateX(400px) skewX(-15deg);
  }
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  overflow: hidden;
}


  .swiper-zoom-container {
    /* height: 320px; */
    height: 100%;
    display: block;
  }

  .collapsed {
    height: 20%;
  }

  .mySwiper {
    transition: height 0.5s;
  }

  .gradient {
    background: rgb(221, 0, 0);
    background: linear-gradient(180deg, rgba(221, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 83%);
  }

  .tabs_list {
    display: flex;
    justify-content: space-around;
    padding: 0;
    list-style: none;
    position: relative;
    /* padding-bottom: 5px; */
    border-bottom: 1px solid rgba(178 172 172);
  }

  .tabs_list li {
    padding: 5px 8px;
  }

  .tabs_list li.activetab {
    /* padding: 5px 8px; */
    /* border-bottom: 1px solid black; */
    /* transition: all 0.5s; */
    position: relative;
  }

  .tabs_list li::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: #000000;
    opacity: 0;
  }

  .tabs_list li.activetab::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: #000000;
    opacity: 1;
    transition: all 0.5s;
  }

  .indi {
    position: relative;
  }

  .card_body_height {
    background: transparent;
    display: flex;
    flex-direction: column;
    color: black;
    
    justify-content: space-between
  }

  .book_button {
   /* From https://css.glass */
   background: rgba(255, 255, 255, 0.2);
   border-radius: 25px;
   box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
   backdrop-filter: blur(2px);
   -webkit-backdrop-filter: blur(4.1px);
   padding: 7px 22px;
   /* padding: 8px; */
   border: none;
   color: white;

   line-height: 1.5;
   font-size: 1rem !important;
   font-weight: 500;
   position: relative;
    overflow: hidden;
   border: none !important;
   display: flex;
   gap: 2px;
   align-items: center;
}

.back_arrow{
  text-shadow: 0px 0px 7px rgba(0, 0, 0, 0.7);
  mix-blend-mode: difference;
}



.bg_image{
  background-image: url('https://plus.unsplash.com/premium_photo-1684407616953-9606e411134b?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
  background-size: cover;
  background-repeat: no-repeat;
  height: 16vh;
  width: 100%;
}

.cover_pic{
  position: absolute;
  bottom: -10%;
  
  left: 50%;
    transform: translate(-50%, 0);
  z-index: 200;
}

.fade-in {
  opacity: 1;
}

.fade-out {
  opacity: 0;
}



.holder {
  position: absolute;
  top: 0;
  left: 1rem;
  padding: 10px;
  font-size: 16px;
  opacity: 0;
  transform: translateY(-100%);
  transition: all 0.5s ease-in-out;
}

.holder.slide-down{
  animation: slidedown 1s ease-in-out;
}

@keyframes slidedown {
  0% {
    opacity: 50;
  }
  
  5% {
    opacity: 0;
    transform: translateY(0.5em);
  }
  
  7% {
    transform: translateY(-1em);
  }
  
  10% {
    opacity: 0;
  }
  
  20% {
    transform: translateY(0);
    opacity: 100;
  }
}


/* .book_button:before {
  content: '';
  position: absolute;
  width: 100px;
  height: 100%;
  background-image: linear-gradient(
      120deg,
      rgba(255,255,255, 0) 30%,
      rgba(255,255,255, .8),
      rgba(255,255,255, 0) 70%
  );
  top: 0;
  left: -100px;
  animation: shine 3s infinite linear;
}

@keyframes shine {
  0% {left: -100px}
  20% {left: 100%}
  100% {left: 100%}
} */

  /* .tab_indicator{
    position: absolute;
    bottom: 0;
   
    height: 3px;
    background: #000000;
    width: calc(90%/4);
  } */

  /* .tab-indicator {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: #007bff;
    transform: translateX(0);
    transition: transform 0.3s ease;
  } */



  .swiper-zoom-container>img,
  .swiper-zoom-container>svg,
  .swiper-zoom-container>canvas {
    width: 85%;
    /* height: 100% !important; */
    object-fit: cover;
    /* border-radius: 0% !important; */
    aspect-ratio: 4/3;
  }



.swiper-zoom-container>img,
.swiper-zoom-container>svg,
.swiper-zoom-container>canvas {
  border-radius: 2%;
  object-fit: cover;
  aspect-ratio: 4/3;
 
}

.swiper-button-next {
  border-radius: 50%;
  padding: 15px;
  background-color: #fff;
  color: #000000;
}

.swiper-button-prev {
  border-radius: 50%;
  padding: 15px;
  background-color: #fff;
  color: #000000;
}

.swiper-button-next:after {
  font-size: 10px;
  font-weight: 900;
  color: #000000;
}

.swiper-button-prev:after {
  font-size: 10px;
  font-weight: 900;
  color: #000000;
}

/* footer  */
.footerlw {
  display: flex;
  list-style: none;
  width: 100%;
  justify-content: center;
  padding: 0px;
}

.footerlw .icon {
  color: #272424;
  position: relative;
  background: #dbdbe0;
  border-radius: 50%;
  margin: 10px;
  width: 38px;
  height: 38px;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.footerlw .tooltip {
  position: absolute;
  top: 0;
  font-size: 14px;
  background: #fff;
  color: #fff;
  padding: 5px 8px;
  border-radius: 5px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.footerlw .tooltip::before {
  position: absolute;
  content: "";
  height: 8px;
  width: 8px;
  background: #fff;
  bottom: -3px;
  left: 50%;
  transform: translate(-50%) rotate(45deg);
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.footerlw .icon:hover .tooltip {
  top: -45px;
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.footerlw .icon:hover span,
.footerlw .icon:hover .tooltip {
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
}

.footerlw .facebook:hover,
.footerlw .facebook:hover .tooltip,
.footerlw .facebook:hover .tooltip::before {
  background: #1877f2;
  color: #fff;
}

/* icon whatsapp */
.footerlw .whatsapp:hover,
.footerlw .whatsapp:hover .tooltip,
.footerlw .whatsapp:hover .tooltip::before {
  background: #028f1c;
  color: #fff;
}

.footerlw .instagram:hover,
.footerlw .instagram:hover .tooltip,
.footerlw .instagram:hover .tooltip::before {
  background: linear-gradient(135deg, #833ab4, #fd1d1d, #fcb045);
  color: #fff;
}

button.btn.p-2.small-button.px-4.d-flex.align-items-center.rounded-pill.active {

  box-shadow: 1px 2px 6px #ada5a5;
}


.footer-shadow {
  box-shadow: 1px 2px 6px #ada5a5 !important;
}




/* event modal  */

.main {
  box-shadow: 0px 0px 0px 0.4px #cdcdcd;
  padding: 6px;
  border-radius: 15px;
}

.l {
  display: flex;
  flex-direction: row;
  /* height: 3em; */
  transform: rotate(180deg);
  justify-content: right;
  /* border-radius: 10px; */
  transition: .4s ease-in-out;
  gap: 0.5rem;
  align-items: center;
}


.albumcover {
  position: relative;
  /* height: 40px; */
  /* width: 40px; */
  align-self: center;
  border-radius: 5px;
}

.albumcover svg {
  transform: rotate(180deg);
  /* padding: 10px; */
  /* height: 20px; */
  /* width: 20px; */
  /* align-self: center; */
  /* border-radius: 5px; */
}

.song {
  position: relative;
  transform: rotate(180deg);
  /* margin-right: 1em; */
  color: black;
  align-self: center;
  
}

.artist {
  font-size: 1em;
}

p.name.mb-0 {
  font-size: 12px;
  font-weight: 500;
}

@keyframes move6 {
  0% {
    height: 0.2em;
  }

  25% {
    height: 0.7em;
  }

  50% {
    height: 1.5em;
  }

  100% {
    height: 0.2em;
  }
}




.notifications-container {
  height: auto;
  font-size: 0.875rem;
  line-height: 1.25rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.popoverbtn:focus{
  outline: none;
}

.alldates{
  padding: 0.4rem 1.5rem;
  border: 1px solid black;
  border-radius: 20px;
  font-size: 1rem;
  font-weight: 600;
}

.details_tab ul{
  margin: 0 !important;
  padding: 0 !important;
}

.popover {
  animation: fadeIn 0.42s ease-in;
}

@keyframes fadeIn {
  0%{
    opacity: 0;
  }

  50%{
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}





.hasVariable{
  /* color: #FD9907; */
}

.popover{
  --bs-popover-border-width: 2px !important;
}


.success {
  padding: 1rem;
  border-radius: 0.375rem;
  background-color: rgb(240 253 244);
}

.succes-svg {
  color: rgb(74 222 128);
  width: 1.25rem;
  height: 1.25rem;
}


.success-prompt-heading {
  font-weight: bold;
  color: rgb(22 101 52);
}

.success-prompt-prompt {
  margin-top: 0.5rem;
  color: rgb(21 128 61);
}

/* .success-button-container {
  display: flex;
  margin-top: 0.875rem;
  margin-bottom: -0.375rem;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
} */

/* .success-button-main {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  background-color: #ECFDF5;
  color: rgb(22 101 52);
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: bold;
  border-radius: 0.375rem;
  border: none;
}

.success-button-main:hover {
  background-color: #D1FAE5;
} */

/* .success-button-secondary {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin-left: 0.75rem;
  background-color: #ECFDF5;
  color: #065F46;
  font-size: 0.875rem;
  line-height: 1.25rem;
  border-radius: 0.375rem;
  border: none;
} */













/* .star {
  height: 0.7rem;
  width: 0.7rem;
} */

/* .success-prompt-prompt{
  box-shadow: 0px 0px 15px 1px #d9d6d6;
  border-radius: 8px;
} */